import React from 'react';
import { TfiYoutube } from 'react-icons/tfi';

// export class IconYouTube extends React.Component {
//     render() {
//         return <div className='icon__tube'><TfiYoutube /></div>
//     }
// }

export const IconYouTube = () => {
    return (
        <div className='icon__tube'><TfiYoutube /></div>
    )
}